/**
 * Converts a User to a Talent with a User
 */
import moment from 'moment'

export class ColorRemoveHexConverter {
  from(val: string | null): string | null {
    return val ? `#${val}` : null
  }
  to(val: string | null, originalValue: string | null): string | null {
    return val?.replace('#', '') ?? null
  }
}
