



































import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator'
import {Repository} from '@/model/resource/Repository'
import {InputRepositorySchema} from '@/schema/resource/Repository/InputRepositorySchema'

@Component
export default class PersistRepositoryView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputRepositorySchema()
  repository = new Repository()

  async created() {
    this.populateResource()
    await this.populate()
  }

  populateResource() {
    this.schema.collectionOrganization.queryAsPage()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.repository.getRepository(id)
    }

    this.$await.done('getRepository')
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }

    await this.repository.persistRepository()
    this.$toast.success('system.success.persist')
    await this.$nav.push('/repository/list')
  }
}
