/**
 * Input Schema of Repository
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {Repository} from '@/model/resource/Repository'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'
import {$} from '@/facade'
import {BoostedDapp} from '@/model/resource/BoostedDapp'
import {Dapp} from '@/model/resource/Dapp'
import InputSelectX from '@/components/InputSelectX.vue'
import {ColorRemoveHexConverter} from '@/converter/ColorRemoveHexConverter'

/* TODO: review generated schema */
export class InputRepositorySchema extends DefaultSchema {
  collectionOrganization = new OrganizationCollection().noPagination()

  readonly name = 'InputRepository'

  readonly fieldSet: FieldSet<Repository> = {
    organization: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        label: this.translateFrom(schema.fieldName),
        items: this.collectionOrganization.items,
      },
    }),
    labelsTokenized: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 256,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    title: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 128,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    colorHex: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'color',
        converter: new ColorRemoveHexConverter(),
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    repositoryUrl: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'text',
        maxlength: 256,
        label: this.translateFrom(schema.fieldName),
        required: true,
        validation: 'required',
      },
    }),
    description: (schema): FieldComponent => ({
      is: Component.InputText,
      bind: {
        type: 'textarea',
        label: this.translateFrom(schema.fieldName),
        maxlength: 600,
        row: 5,
        class: 'col-span-2',
      },
    }),
  }
}
